/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import { ENABLE_MIXPANEL } from 'env';

export type TrackingError = {
  'Failure Type': string;
  error?: string;
  errorDetails?:
    | { [key: string]: string | object | unknown | number }
    | unknown;
};

export type MixpanelData = {
  [key: string]: unknown | object | string | number;
};

const mixpanelInfo = (type: string, data?: MixpanelData) => {
  mixpanel.track(type, {
    client: 'frontend',
    ...data,
  });
};

const mixpanelError = (type: string, error: TrackingError) => {
  mixpanel.track(type, {
    client: 'frontend',
    ...error,
  });
};
const mixpanelStartTracking = (type: string, data?: MixpanelData) => {
  mixpanelInfo(type, data);
  mixpanel.time_event(`${type} Duration`);
};

const mixpanelStopTracking = (type: string, data?: MixpanelData) => {
  mixpanelInfo(`${type} Duration`, {
    client: 'frontend',
    ...data,
  });
};

const getMixpanelFunctions = () => {
  if (ENABLE_MIXPANEL) {
    return {
      info: mixpanelInfo,
      error: mixpanelError,
      track: {
        start: mixpanelStartTracking,
        stop: mixpanelStopTracking,
      },
    };
  }
  return {
    info: () => {},
    error: () => {},
    track: {
      start: () => {},
      stop: () => {},
    },
  };
};

export default getMixpanelFunctions();
