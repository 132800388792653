import React, { useCallback, useEffect, useState } from 'react';
import conidaLogo from 'public/images/datacosmos/conida-logo.png';
import type { IAPIAppValue, IApplication } from 'datacosmos/types/applications';
import { btoaSafe } from 'utils/common/btoaSafe';
import { useApplicationCatalog } from 'datacosmos/stores/ApplicationCatalogContext';
import OpenedAppCard from '../SubscriptionApps/Common/OpenedAppCard';
import UnopenedAppCard from '../SubscriptionApps/Common/UnopenedAppCard';
import { Input, NumberInput } from 'opencosmos-ui';
import { useClickedStacItem } from 'datacosmos/utils/hooks/useClickedStacItem';
import { clientTranslate, useLocalisation } from 'utils/hooks/useLocalisation';
import { useProjects } from 'datacosmos/stores/ProjectProvider';
import Spinner from 'opencosmos-ui/src/core/Spinner/Spinner';
import { submitWorkflow } from 'api/pdgs/service';
import { PointLayer } from 'datacosmos/entities/pointLayer';
import { LayerSourceType } from 'datacosmos/entities/layer';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import bboxPolygon from '@turf/bbox-polygon';

type Props = {
  app: IApplication;
};

export const CoregistrationApp: IApplication = {
  get id() {
    return btoaSafe(
      JSON.stringify(
        this.name +
          JSON.stringify(this.provider) +
          this.description +
          this.appScreenshotUrl
      ).substring(0, 75)
    );
  },
  name: clientTranslate('datacosmos.applications.coregistration.title'),
  description: clientTranslate(
    'datacosmos.applications.coregistration.description'
  ),
  inputs: [
    {
      field: 'imageId',
      example: '',
    },
    {
      field: 'matchLocationLng',
      example: '',
    },
    {
      field: 'matchLocationLat',
      example: '',
    },
  ],
  values: {
    imageId: {
      value: '',
      isError: false,
      message: '',
    },
    matchLocationLng: {
      value: null,
      isError: false,
      message: '',
    },
    matchLocationLat: {
      value: null,
      isError: false,
      message: '',
    },
  },
  provider: {
    id: 1,
    // If the name is blank, the logo will be full width.
    name: '',
    description:
      'Comisión Nacional de Investigación y Desarrollo Aeroespacial del Perú',
    url: 'https://www.gob.pe/conida',
    icon_url: conidaLogo,
  },
  shortDescription: clientTranslate(
    'datacosmos.applications.coregistration.shortDescription'
  ),
  renderer: (app: IApplication) => <Coregistration app={app} />,
  appScreenshotUrl: '',
  tags: [],
};

const Coregistration = ({ app }: Props) => {
  const {
    setInputData,
    toggleAppInstall,
    getInstalledStatus,
    shouldAutoOpen,
    setSelectedInstalledApp,
  } = useApplicationCatalog();

  const { translate } = useLocalisation();
  const { addLayer, removeLayersBySourceType } = useMapLayers();

  const updatePointLayer = (point: number[]) => {
    removeLayersBySourceType(LayerSourceType.COREGISTRATION_MARKER);
    const marker = new PointLayer(
      LayerSourceType.COREGISTRATION_MARKER,
      'orthorecficationMatchLocation',
      point
    );
    addLayer(marker);
  };

  const { clickedStacLayer } = useClickedStacItem({
    outlineClickedItem: false,
    onImageClick: (img, point) => {
      updatePointLayer(point);
      setInputData(app.name, {
        ...app.values,
        imageId: { value: img.item.id, isError: false, message: '' },
        matchLocationLng: { value: point[0], isError: false, message: '' },
        matchLocationLat: { value: point[1], isError: false, message: '' },
      });
    },
  });

  const { currentScenario, fetchCurrentProjectItems } = useProjects();

  const [isAppOpened, setIsAppOpened] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    return () =>
      removeLayersBySourceType(LayerSourceType.COREGISTRATION_MARKER);
  }, [removeLayersBySourceType]);

  const setValue = useCallback(
    (key: string, value: IAPIAppValue['value']) => {
      setInputData(app.name, {
        ...app.values,
        [key]: { value, isError: false, message: '' },
      });
    },
    [app.name, app.values, setInputData]
  );

  const handleCoordinateChange = (inputName: string, value: number) => {
    const point =
      inputName === 'matchLocationLng'
        ? [value, app.values.matchLocationLat.value as number]
        : [app.values.matchLocationLng.value as number, value];
    updatePointLayer(point);
    setValue(inputName, value);
  };

  const inputs = () => {
    const { matchLocationLat, matchLocationLng } = app.values;
    return (
      <div className="flex flex-col gap-4 mb-4 mt-4">
        <span>Click a full resolution image on the map</span>
        <Input
          type="text"
          readOnly
          value={clickedStacLayer?.item.id}
          placeholder={translate(
            'datacosmos.applications.coregistration.inputs.fullResImg.placeholder'
          )}
          label={{
            position: 'top',
            text: translate(
              'datacosmos.applications.coregistration.inputs.fullResImg.title'
            ),
            wrapLabelText: true,
          }}
        />
        <div>
          <h3>
            {translate(
              'datacosmos.applications.coregistration.inputs.matchLocation'
            )}
          </h3>
          <div className="flex flex-col gap-2 p-2">
            <NumberInput
              fill
              label={translate('validation.point.lat')}
              value={matchLocationLat.value as number}
              onChange={(value) =>
                handleCoordinateChange('matchLocationLat', value)
              }
              formatOptions={{ maximumFractionDigits: 5 }}
              minValue={-90}
              maxValue={90}
            />
            <NumberInput
              fill
              label={translate('validation.point.lng')}
              value={matchLocationLng.value as number}
              onChange={(value) =>
                handleCoordinateChange('matchLocationLng', value)
              }
              formatOptions={{ maximumFractionDigits: 5 }}
              minValue={-180}
              maxValue={180}
            />
          </div>
        </div>
        {app.values.matchLocationLng.isError && (
          <div>
            <small style={{ color: '#ff0000' }}>
              {app.values.matchLocationLng.message}
            </small>
          </div>
        )}
      </div>
    );
  };

  if (shouldAutoOpen || (isAppOpened && getInstalledStatus(app))) {
    return (
      <OpenedAppCard
        app={app}
        inputsRenderer={inputs}
        setIsAppOpened={setIsAppOpened}
        toggleAppInstall={toggleAppInstall}
        isInstalled={getInstalledStatus(app)}
        handleSubmit={async () => {
          const matchLocationPoint = {
            lat: app.values.matchLocationLat.value as number,
            lng: app.values.matchLocationLng.value as number,
          };
          if (
            !booleanPointInPolygon(
              [matchLocationPoint.lng, matchLocationPoint.lat],
              bboxPolygon(clickedStacLayer?.item.bbox!)
            )
          ) {
            setInputData(app.name, {
              ...app.values,
              matchLocationLng: {
                value: matchLocationPoint.lng,
                isError: true,
                message: translate(
                  'datacosmos.applications.coregistration.errors.pointOutsideItem'
                ),
              },
            });
            return;
          }
          setIsSubmitting(true);
          const { data } = await submitWorkflow({
            body: {
              name: 'co-register-image',
              inputs: {
                id: clickedStacLayer?.item.id ?? '',
                collection: clickedStacLayer?.item.collection ?? '',
                project: currentScenario?.id ?? '',
                'x-coordinate': `${matchLocationPoint.lng ?? ''}`,
                'y-coordinate': `${matchLocationPoint.lat ?? ''}`,
                'search-radius': '750', //Search radius cannot be set by the user
              },
            },
          });
          setIsSubmitting(false);

          if (data?.name) {
            await fetchCurrentProjectItems();
          }
        }}
        submitButtonLabel={
          isSubmitting ? (
            <Spinner size={18} />
          ) : (
            translate('datacosmos.applications.global.buttons.submit')
          )
        }
      />
    );
  }

  return (
    <UnopenedAppCard
      app={app}
      isInstalled={getInstalledStatus(app)}
      setIsAppOpened={setIsAppOpened}
      setSelectedInstalledApp={setSelectedInstalledApp}
      toggleAppInstall={toggleAppInstall}
    />
  );
};

export default Coregistration;
