import type { PdgsWorkflow } from './types';
import { hosts } from 'api/hosts';
import { apiFetchHandler } from 'api/clientFetch';
import { toaster } from 'toaster';
import { clientTranslate } from 'utils/hooks/useLocalisation';

export type GetWorkflowStatusesParams = {
  start_time: string | undefined;
  end_time: string | undefined;
  mission_id: number;
};

export const getWorkflowStatuses = apiFetchHandler<
  PdgsWorkflow[] | null,
  GetWorkflowStatusesParams
>({
  endpoint: ({ mission_id, ...times }) => {
    const hasQueryParams = !Object.values(times).every((v) => v === undefined);
    const timesAsQueryParam = Object.entries(times)
      .filter(([_, v]) => v !== undefined)
      .map(([k, v]) => `${k}=${v!}`)
      .join('&');

    return `/applications/mission/${encodeURIComponent(mission_id)}/workflows${
      hasQueryParams ? `?${encodeURIComponent(timesAsQueryParam)}` : ''
    }`;
  },
  host: hosts.data.v0,
  errorMessage: 'Could not get workflow statuses',
  method: 'GET',
  errorDescription: (e) => e.join('; '),
});

export type ResubmitWorkflowBody = {
  name: string;
  labels?: Record<string, string>;
  inputs: Record<string, string>;
};

export type ResubmitWorkflowResponse = {
  name: string;
};

export const submitWorkflow = apiFetchHandler<
  ResubmitWorkflowResponse,
  {},
  ResubmitWorkflowBody
>({
  host: hosts.data.v0,
  endpoint: '/applications/workflow/submit',
  errorMessage: clientTranslate(
    'datacosmos.applications.global.workflows.errors.post'
  ),
  errorDescription: (err) => err.join('; '),
  method: 'POST',
  onSuccess: () => {
    toaster.show({
      message: clientTranslate(
        'datacosmos.applications.global.workflows.success'
      ),
      intent: 'success',
    });
  },
});
